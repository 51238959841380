export const mainPATH = '/';
export const funktionenPATH = '/function';
export const prozessPATH = '/prozess';
export const beratungPATH = '/beratung';
export const preisePATH = '/preise';
export const unternehmenPATH = '/unternehmen';
export const faqPATH = '/faq'
export const wikiPATH = "/wiki"
export const impressumPATH = "/impressum"
export const kontaktPATH = "/kontakt"
export const articlePATH = "/article/:id"
export const articlesPATH = "/articles"
export const datenschutzPATH = "/datenschutz"
export const searchPATH = "/search"
export const errorPATH = "/*" 