import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { sendEmailThunk } from "../../services/thunks/swagger-thunk";
import styles from "./FormPage.module.css";
import useForm from "../../hooks/useForm";
import { Link } from "react-router-dom";
import { datenschutzPATH } from "../../utils/constants";

function FormPage() {

  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  
  const { hadleChangeUserData, userData } = useForm({
    to: "v.edikh@uub-schwan.de",
    subject: "No-Reply: Anfrage von der Webseite",    
    name: "",
    email: "",
    phone: "",
    company: "", // renamed from firmName
    service: "", // renamed from predefined
    message: "",
    agreeToTerms: true, // new field for the checkbox
  });

  const hadleSubmit = (e) => {
    e.preventDefault();
    // dispatch(fetchLoginThunk(userData.email,  userData.password));
    dispatch(sendEmailThunk(userData));

  };

  const inputsFormPage = [
    {
      name: "name",
      type: "text",
      placeholder: "Name",
      required: true,
    },
    {
      name: "email",
      type: "email",
      placeholder: "E-mail",
      required: true,
    },
    {
      name: "phone",
      pattern:"[0-9+\\-]*" ,
      title:`Sie können Zahlen und Symbole "-" und "+" verwenden`,
      type: "text",
      placeholder: "Telefonnnummer",
      required: true,
    },
    {
      name: "company",
      type: "text",
      placeholder: "Unternehmen",
      required: true,
    },
    {
      name: "service",
      type: "select",
      placeholder: "Free",
      options: [
        { value: "free", text: "Free" },
        { value: "basic", text: "Basic" },
        { value: "enterprise", text: "Enterprise" },
      ],
      required: true,
    },
  ];

  return (
    <section className={styles.main_container}>
      <h2 className={styles.title}>Nehmen Sie per Formular Kontakt mit uns auf oder rufen Sie uns an</h2>
      <div className={styles.button}>
          <a href="tel:+4933127341814" className={styles.link}>
            T. +49 331 27341814
          </a>
      </div>
      <form className={styles.form} onSubmit={hadleSubmit}>
        {inputsFormPage.map((input) => {
          if (input.type === "select") {
            return (
              <select
                key={input.name}
                name={input.name}
                className={styles.input}
                onChange={hadleChangeUserData}
                value={userData[input.name]}
                required={input.required}
              >
                {input.options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                ))}
              </select>
            );
          } else {
            return (
              <input
                key={input.name}
                className={styles.input}
                onChange={hadleChangeUserData}
                value={userData[input.name]}
                {...input}
              />
            );
          }
        })}
        <textarea
          name="message"
          id="message"
          className={`${styles.input} ${styles.input__textarea}`}
          placeholder="Ihre Mitteilung an uns"
          rows={9}
          onChange={hadleChangeUserData}
          value={userData.message}
        />
        <div className={styles.chekbox_container}>
          <input required type="checkbox" name="" id="" />
          <p className={styles.checkbox__label}>Ja, ich stimme der <Link target="_blank" to={datenschutzPATH}>Datenschutzerklärung</Link> zu</p>       
        </div>
        <button className={styles.button_contact}>Abschicken</button>
      </form>
      <p className={styles.request_meassage}>
        Vielen Dank für Ihre Nachricht. Wir melden uns zeitnah bei Ihnen!
      </p>
    </section>
  );
}

export default FormPage;
