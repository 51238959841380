// BackToTopButton.jsx
import React, { useState, useEffect } from 'react';
import GreenOwl from '../../image/owl.svg'; // Adjust the path as necessary
import styles from './BackToTopButton.module.css'; // Assuming you have a CSS module for styling

const BackToTopButton = () => {
  const [showButton, setShowButton] = useState(false);


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // For smooth scrolling
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      // Show button if page is scrolled more than 50 pixels
      if (window.scrollY > 150) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return showButton ? (
    <button onClick={scrollToTop} className={styles.backToTopButton}>
      <img src={GreenOwl} alt="Owl" className={styles.owlIcon} />
      Zurück nach oben
    </button>
  ) : null;
};

export default BackToTopButton;