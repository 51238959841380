import React, { useEffect } from "react";
import styles from "../../components/Price/Price.module.css";
import styles2 from "./PreisePage.module.css";
import Price from "../../components/Price/Price";
import PriceTarif from "../../components/PriceTarif/PriceTarif";

function PreisePage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []); 
  
  return (
    <section className={styles2.main_container}>
      <div className={styles2.background}>
        {/* <p className={styles2.spoiler}>
          Funktionen
        </p> */}
        {/* <h2 className={`${styles.title} ${styles2.title}`}>
          Mehr als Excel – software as a service
        </h2> */}
        {/* <p className={`${styles.sub_title} ${styles2.sub_title}`}>
          Mit Compleon Solutions steuern Sie die Rechtspflichten, Maßnahmen und
          Verantwortlichkeiten in den Bereichen Energie, Umwelt- und
          Arbeitsschutz für Ihr gesamtes Unternehmen zuverlässig und sicher –
          jederzeit und von überall.
        </p> */}
      </div>

      <Price isSpoiler={false} />
      <PriceTarif />
    </section>
  );
}

export default PreisePage;