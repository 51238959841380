import React, { useEffect, useMemo, useState } from "react";
import styles from "./PriceTarif.module.css";
import { MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import { fetchGetPriceThunk } from "../../services/thunks/swagger-thunk";
import { TailSpin } from "react-loader-spinner";
import { BiSolidError } from "react-icons/bi";
import { NavLink, useLocation } from "react-router-dom";

function PriceTarif() {
  const dispatch = useDispatch();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    dispatch(fetchGetPriceThunk());

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const countOfReview = useMemo(() => {
    if (windowWidth <= 680) {
      return 1;
    } else {
      return 2;
    }
  }, [windowWidth]);

  const { getPriceRequest, getPriceRequestSuccess, getPriceRequestFailed } =
    useSelector((store) => store.swaggerDataReducer);

  const priceData =
    useSelector((store) => store.swaggerDataReducer.priceData) || [];

  const {pathname} = useLocation();

  
  return (
    <section
    className={
      (pathname === "/function")
        ? `${styles.main_container__function}`
        : `${styles.main_container}`
    }
    // className={styles.main_container}
    >
      {!getPriceRequest &&
       (getPriceRequestFailed || priceData.length === 0) && (
          <div className={styles.failed_container}>
            <BiSolidError className={styles.error_sign} size={80} />
            <h3
              className={`${styles.advice_title} ${styles.advice_title__warning}`}
            >
              An dieser Stelle wurde noch kein Content generiert. Bei Fragen zum Inhalt wenden Sie sich bitte per Kontaktformular an uns. Vielen Dank.            </h3>
          </div>
        )}
      {getPriceRequest && (
        <div className={styles.spiner}>
          <TailSpin
            color="#292982"
            radius={"3px"}
            width={100}
            height={100}
            wrapperClass={styles.spiner}
          />
        </div>
      )}
      <ul className={styles.tarif_container}>
        {priceData.map((item, id) => (
          <li key={item.id}
            style={{border: id === 1 ? '1px solid #EC5E43' : ''}}
            className={styles.tarif_item}>
            <h2 className={styles.title}>
              {item.attributes.Title}
            </h2>
            <p className={styles.sub_title}>
              {item.attributes.Subtitle}
            </p>
            <ul className={styles.features_container}>
              {item.attributes.items.map((item) => (
              <li key={item.id} className={styles.features_item}>
                {item.status ? (
                  <MdOutlineDone className={styles.sign__allowable} />
                ) : (
                  <RxCross2 className={styles.sign__unallowable} />
                )}
                <p className={styles.feature_text}>
                  {item.itemName}
                </p>
              </li>
              ))}
              
            </ul>
            <p className={styles.price}>
              {item.attributes.Price === '0' ? (
              // <span className={styles.price_value}>
              <>
               {item.attributes.ButtonSubtitle}
              </>
              ) : (
                <>
                <span className={styles.price_value}>
                  {item.attributes.Price.split('/').map(item => item.trim())[0]}
                </span> 
                 &nbsp; / &nbsp;{item.attributes.Price.split('/').map(item => item.trim())[1]}
                </>
              )}
            </p>
            <a 
              className={item.id === 2 ? `${styles.button} ${styles.button__special}` : `${styles.button}`}
              href={item.attributes.ButtonPrice.link}
              target="_blank"
              rel="noreferrer"
            >{item.attributes.ButtonPrice.Title}</a>
            <p className={styles.button__subtitle}>
              {item.attributes.ButtonSubtitle === 'kostenfrei' ? (
                <></>
              ) : (
                <>
                {item.attributes.ButtonSubtitle}
                </>
              )}
            </p>
          </li>
        ))}
      </ul> 
      <Swiper 
        direction={"horizontal"}
        slidesPerView={countOfReview}
        spaceBetween={10}
        initialSlide={1} 
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className={`mySwiper ${styles.wrapper}`}
      >
        {priceData.map((item, id) => (
         <SwiperSlide key={item.attributes.id} className={styles.swiper_slide}>
          <div             
            style={{border: id === 1 ? '1px solid #EC5E43' : ''}}
            key={item.attributes.id} 
            className={`${styles.tarif_item} ${styles.tarif_item__mobile}`}>
            <h2 className={styles.title}>
              {item.attributes.Title}
            </h2>
            <p className={styles.sub_title}>
              {item.attributes.Subtitle}
            </p>
            <ul className={styles.features_container}>
              {item.attributes.items.map((item) => (
              <li key={item.id} className={styles.features_item}>
                {item.status ? (
                  <MdOutlineDone className={styles.sign__allowable} />
                ) : (
                  <RxCross2 className={styles.sign__unallowable} />
                )}
                <p className={styles.feature_text}>
                  {item.itemName}
                </p>
              </li>
              ))}
              
            </ul>
            <p className={styles.price}>
              {item.attributes.Price === '0' ? (
              <>
               {item.attributes.ButtonSubtitle}
              </>
              ) : (
                <>
                <span className={styles.price_value}>
                  {item.attributes.Price.split('/').map(item => item.trim())[0]}
                </span> 
                 &nbsp; / &nbsp;{item.attributes.Price.split('/').map(item => item.trim())[1]}
                </>
              )}
            </p>
            {!item.attributes.ButtonPrice.isExternal ? (
             <a 
               className={id === 1 ? `${styles.button} ${styles.button__special}` : `${styles.button}`}
               href={item.attributes.ButtonPrice.link}
               target="_blank"
               rel="noreferrer"
             >
              {item.attributes.ButtonPrice.Title}
            </a>
            ) : (
              <NavLink
              className={id === 1 ? `${styles.button} ${styles.button__special}` : `${styles.button}`}
               to={item.attributes.ButtonPrice.link}>
              {item.attributes.ButtonPrice.Title}
              </NavLink>
            )}
           
            <p className={styles.button__subtitle}>
              {item.attributes.ButtonSubtitle === 'kostenfrei' ? (
                <></>
              ) : (
                <>
                {item.attributes.ButtonSubtitle}
                </>
              )}
            </p>
          </div>
        </SwiperSlide>

        ))}
      </Swiper>
    </section>
  );
}

export default PriceTarif;